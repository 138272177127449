@font-face {
    font-family: MuseoSans;
    src: url("./fonts/MuseoSans_700.otf");
    font-weight: bold;
}

@font-face {
    font-family: ProximaNova;
    src: url("./fonts/ProximaNova.otf");
    font-weight: normal;
}

body {
    font-size: 14px;
    margin: 10vh 0 0 0 !important;
    overflow-x: hidden;
    font-family: ProximaNova, serif !important;
    padding: 0 0 5rem 0;
}

.full-screen {
    background-color: #0005;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;
}

h1,
h2,
h3,
h4,
h5,
h6,
.heading {
    font-family: "MuseoSans", serif !important;
    padding: 1rem 0;
    margin: 0;
}

button {
    transition: all 0.2s;
}

.fw {
    width: 100%;
}

a {
    text-decoration: initial;
    color: initial;
}

input::placeholder {
    color: #fff;
    font-family: "Azeret Mono", cursive !important;
}

.flex-center-vertical {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
}

.flex-center-hori {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.mv-0 {
    margin-top: 0;
    margin-bottom: 0;
}

.mh-0 {
    margin-right: 0;
    margin-left: 0;
}

.ph-5 {
    padding: 0 2rem;
}

.pv-0 {
    padding-top: 0;
    padding-bottom: 0;
}

.ph-0 {
    padding-right: 0;
    padding-left: 0;
}

.m-0 {
    margin: 0;
}

.p-0 {
    padding: 0;
}

.tac {
    text-align: center;
}

h1 {
    font-size: 52px;
}

h2 {
    font-size: 52px;
}

h3 {
    font-size: 48px;
}

h4 {
    font-size: 32px;
}

h5 {
    font-size: 24px;
}

h6 {
    font-size: 18px;
}

input::placeholder {
    color: #0005 !important;
    text-align: center;
}
