nav {
	height: calc(100vh - 10vh);
	width: 100%;
	position: fixed;
	display: none;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	z-index: 899;
	background: #FFFFFF;
}

nav .nav-item {
	text-decoration: none;
	font-size: 48px;
	transition: 300ms all;
	width: 100%;
	text-align: center;
	padding: 0.5rem 0;
}

nav .nav-item:hover {
	background: #d13350;
	color: #00a1e2;
}

.hamburger {
	z-index: 999;
	position: fixed;
	top: 0;
	left: 0;
	height: 10vh;
	user-select: none;
	cursor: pointer;
	display: flex;
	padding: 0 2vh;
	margin: 0;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}
