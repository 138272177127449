.landing {
	height: calc(100vh - 10vh);
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	background-size: cover;
	background: url("../../images/landing.jpg") no-repeat center;
}

.card {
	border: 2px solid #00a1e2;
	box-shadow: 0 0 15px #d13350;
	padding: 1rem;
	border-radius: 1rem;
}

.about {
	position: relative;
	padding: 4rem 0;
}

.about div {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
}

.left {
	position: absolute;
	left: 0;
	top: 10%;
	height: 80%;
	width: 2%;
	background: #d13350;
}

.right {
	position: absolute;
	right: 0;
	top: 10%;
	height: 80%;
	width: 2%;
	background: #d13350;
}